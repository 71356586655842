import React, { useState } from "react"
import Select from "react-select"
import ReactTooltip from "react-tooltip"
// import CabinetMaterial from './cabinetMaterialvalue'
import logo from "../images/SideTrayVertical.jpg"
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap"

class FalseCeilingSimpleWithTrayVertical extends React.Component {
  constructor() {
    super()
    this.state = {
      measurement: "100",
      verticalMeasurement: "0",
      pricePerSft: "",
      pricePerSftVertical: "",
      noOfLightPoints: "9",
      noOfLightPointsBig: "6",
      total: "",
      noOfLightPointsSmall: "0",
      noOfSwitchBoard: "0",
      material: "",
      smallLedPrice: "250",
      bigLedPrice: "500",
      ropeLight: "0",
      switchboardPrice: "2000",
      ropeLightPrice:"70"
    }
  }

  handlemeasurement = event => {
    this.setState({
      measurement: event.target.value,
    })
  }

  

  handlePricePerSftVertical = event => {
    this.setState({
      pricePerSftVertical: event.target.value,
    })
  }
  handleverticalMeasurement = event => {
    this.setState({
      verticalMeasurement: event.target.value,
    })
  }

  handlePricePerSft = event => {
    this.setState({
      pricePerSft: event.target.value,
    })
  } 
  handleMaterial = event => {
    this.setState({
      material: event.target.value,
      pricePerSft: event.target.value,
      pricePerSftVertical: event.target.value - 10,
    })
    console.log(event.target)
  }

  
  exe = event => {
    this.setState({
      total:
        (parseInt(this.state.measurement) * parseInt(this.state.pricePerSft)) +
        (parseInt(this.state.verticalMeasurement) * parseInt(this.state.pricePerSftVertical))
    })
    event.preventDefault()
  }

  render() {
    return (
      <div>
        <Form className="text-center" onSubmit={this.exe}>
          <FormGroup row>
            <Label for="id_total_sft" sm={4}>
              Total Area(Except Tray Vertical)
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.measurement}
                onChange={this.handlemeasurement}
                placeholder="False Ceiling area in SFT"
                data-tip data-for="meas"
              />
              {/* <ReactTooltip id="meas" place="top" effect="solid" borderColor="green">
              Total SFT mention करे(Tray Vertical को छोड़कर)
                </ReactTooltip> */}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_tray_vertical" sm={4}>
              Tray Vertical
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.verticalMeasurement}
                onChange={this.handleverticalMeasurement}
                placeholder="Vertical measurement in SFT"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_ceiling_material" sm={4}>
              Ceiling Material
            </Label>
            <Col sm={4}>
              <select
                className="form-control mb-3"
                value={this.state.material}
                onChange={this.handleMaterial}
              >
                <option selected hidden enabled>
                  Select False Ceiling Material
                </option>
                <option value="70">Gypsum</option>
                <option value="60">POP</option>
                <option value="90">PVC</option>
              </select>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              Price Per Sft(Without Tray Vertical)
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.pricePerSft}
                onChange={this.handlePricePerSft}
                placeholder="Price per SFT"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              Price Per Sft(Vertical*)
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.pricePerSftVertical}
                onChange={this.handlePricePerSftVertical}
                placeholder="Price Per SFT(Vertical*)"
              />
            </Col>
          </FormGroup>

          
          
          
          <FormGroup check row>
            <Col sm={{ size: 4  , offset: 2 }}>
              <button className="btn btn-outline-success"> CALCULATE NOW</button>

            </Col>
          </FormGroup>
          {/* <img src={logo} alt="Logo" width="400" height="400"/> */}
        </Form>
        <b>Total Cost:</b> {this.state.total} Rs
        {/* <a href="/measurement-how-to-measure-false-ceiling"> फाल्स सीलिंग का measurement कैसे करते हैं ? </a> */}

      </div>
    )
  }
}

export default FalseCeilingSimpleWithTrayVertical
