import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Sidebar from "../components/falseceilingsidebar"
import Select from 'react-select';
import { Container, Row, Col } from "react-bootstrap"


import FCSimpleCalculator from "./falseCeilingSimpleCalculator"
import FCSimpleWithWiring from "./falseCeilingSimpleWithWiringSeparate"
import FCSimpleWithTrayVertical from "./falseCeilingSimpleWithTrayVertical"
import FCSimpleWithWiringAndTrayVertical from "./falseCeilingSimpleWithWiringAndTrayVertical"

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 2
      }}
  />
);

const ColoredYelloLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1
      }}
  />
);

const ColoredThinLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1
      }}
  />
);

const FalseCeilingCostCalculator = () => (
  <Layout>
   <SEO
        title="FALSE CEILING CALCULATOR"
        keywords={[
        "False Ceiling Calculator"
          
        ]}
      />
    <Container>
      <h1>FALSE CEILING CALCULATOR</h1>
      <Row>
        <Col md="9">
        {/* <center><h3>False Ceiling Area Calculator</h3></center>
        <FCAreaCalculator/> */}

        <center><h4><u>QUICK CALCULATOR</u></h4></center>
        
        <ColoredLine color="green" />
        <ColoredThinLine color="#9400D3"/>
        <b>क्या आपको पता है फाल्स सीलिंग का Total Area कैसे निकाला जाता है? अगर नहीं पता तो पहले ये <Link to="https://youtu.be/KMDAhgMctn4">वीडियो</Link> देखें</b>
        <ColoredThinLine color="#9400D3"/>
        
        <h5>Get your Quick False Ceiling Estimates</h5>
        <FCSimpleCalculator/>
        <ColoredLine color="green" />
        <br></br>
        <h5>Get your Quick False Ceiling Estimates if the <u>Wiring Cost is separate for each Light Points</u></h5>
        <FCSimpleWithWiring/>
        <ColoredLine color="green" />
        
      
        <ColoredThinLine color="#9400D3"/>
        <b>क्या आपको पता है फाल्स सीलिंग में Trey Vertical क्या होता है और उसका charge अलग से क्यों होता है ? अगर नहीं पता तो पहले ये <Link to="https://youtu.be/KMDAhgMctn4">वीडियो</Link> देखें</b>
        <ColoredThinLine color="#9400D3"/>
        <h5>Get your Quick False Ceiling Estimates if the <u>Tray Vertical cost is separate</u> </h5>
        <FCSimpleWithTrayVertical/>

        <br></br>
        <ColoredLine color="green" />
        <ColoredThinLine color="#9400D3"/>
        <b>क्या आपको इस Calculator को समझने में परेशानी हो रही है अगर हाँ तो इस <Link to="https://youtu.be/Crra1NvJ1Xk">link</Link> पर क्लिक करके पहले वीडियो देखें</b>
        <ColoredThinLine color="#9400D3"/>
        <h5>Get your Quick False Ceiling Estimates if the <u>Wiring Cost is separate for each Light Points and Tray Vertical is separate</u></h5>
        <FCSimpleWithWiringAndTrayVertical/>
        <ColoredLine color="green" />
        <ColoredYelloLine color="#9400D3"/>
        <b>क्या आप मेरा Youtube Channel subscribe करना चाहेंगे ? अगर हाँ तो इस <Link to="https://www.youtube.com/channel/UCkMSooIIGsUGgt86mkPq1mg?sub_confirmation=१">link</Link>  पर क्लिक करें </b>
        <ColoredYelloLine color="#9400D3"/>
        <ColoredLine color="green" />
        </Col>
        <Col md="3">
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            <Sidebar />
          </div>
        </Col>
      </Row>
     
    </Container>
  </Layout>
)

export default FalseCeilingCostCalculator
